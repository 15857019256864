/* Reset general */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Contenedor de las opciones: Aseguramos que los botones estén alineados de manera uniforme */
.options-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Espacio entre los botones */
  justify-content: center; /* Centrar los botones horizontalmente */
  width: 100%; /* Asegurar que el contenedor ocupe todo el ancho posible */
}

/* Ajuste de tamaño para que los botones sean más consistentes y se ajusten al contenedor */
.option-button {
  flex: 1 1 45%; /* Cada botón ocupará aproximadamente el 45% del ancho del contenedor */
  max-width: 45%; /* Limitar el ancho máximo a 45% para asegurar consistencia */
  background-color: #1cb0f6;
  color: #fff;
  border: none;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.option-button:hover {
  background-color: #17a1dd;
}

body {
  font-family: "Helvetica Neue", sans-serif;
  background-color: #f4f7f6;
  color: #333;
  line-height: 1.6;
  overflow-y: scroll;
}

/* Contenedor principal */
.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh; /* Altura mínima para que ocupe toda la pantalla */
  padding: 20px;
  overflow-y: auto; /* Habilita scroll si es necesario */
}

/* Tarjetas para quiz y mensaje */
.card {
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
  margin-bottom: 20px;
}

h2 {
  font-size: 24px;
  color: #4c9f38;
  margin-bottom: 20px;
}

p {
  margin-bottom: 15px;
}

/* Botones genéricos */
button {
  background-color: #1cb0f6;
  color: #fff;
  border: none;
  padding: 12px 20px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #17a1dd;
}

/* Botón seleccionado */
.selected {
  background-color: #4c9f38;
}

/* Opciones de respuesta */
.response-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0;
}

.response-button {
  background-color: #1cb0f6;
  color: #fff;
  border: none;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  transition: background-color 0.3s ease, box-shadow 0.3s ease,
    transform 0.3s ease;
  width: 100%;
  max-width: 400px; /* Ajustar para mantener los botones del mismo tamaño */
  margin-bottom: 10px; /* Espacio entre los botones */
}

.response-button:hover {
  background-color: #17a1dd;
}

/* Botón de respuesta seleccionado */
.response-button.selected {
  background-color: #4c9f38; /* Cambiar el color del botón para resaltar la selección */
  color: #fff;
  border: 2px solid #388e3c; /* Añadir un borde para enfatizar el botón seleccionado */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Añadir una sombra para dar una apariencia de elevación */
  transform: scale(
    1.05
  ); /* Incrementar el tamaño ligeramente para hacer que se destaque */
}

/* Caja de mensaje */
.message-box {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  margin-bottom: 20px;
  text-align: left;
}

/* Texto de mensaje */
.message-box p,
.message-box strong {
  font-size: 16px;
  color: #333;
}

.message-box strong {
  font-size: 18px;
  color: #4c9f38;
}

/* Input de mensaje personalizado */
.custom-message-input {
  width: 100%;
  padding: 10px;
  min-height: 150px;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-top: 10px;
  font-size: 16px;
}

/* Botón de envío de respuesta */
.submit-response-button {
  background-color: #4c9f38;
  color: white;
  padding: 12px 20px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
}

.submit-response-button:hover {
  background-color: #3f8e2d;
}

/* Pantalla final de respuesta */
.response-sent-message {
  text-align: center;
  padding: 20px;
}

/* Animación de fadeIn */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Para todas las tarjetas */
.card.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}
